import React, { useState } from 'react'
import './styles/Body.css';
import routes from '../constants/routes';
import {
    formVariables,
    messagesConsult,
    messagesSubscribe,
    messagesUnSubscribe, titlesConsult,
    titlesSubscribe,
    titlesUnSubscribe
} from '../constants/messages';
import variables from '../constants/variables';
import { useMediaQuery } from 'react-responsive';
// import { Alert, Col, Row, Button } from 'antd';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import { SocialIcon } from 'react-social-icons';
import axios from 'axios';
import PurdyCorrect from '../assets/enviado.svg';
import PurdyError from '../assets/error.svg';
import PurdyAlert from '../assets/error.svg';
import PurdyRevision from '../assets/revision.svg';
import PurdyPermisos from '../assets/permisos.svg';
import PurdySinResultados from '../assets/sinResultados.svg';


const Body = () => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' })
    const [userName, setUserName] = useState('')
    const [userNameError, setUserNameError] = useState(false)
    const [userId, setUserId] = useState('')
    const [userIdError, setUserIdError] = useState(false)
    const [userType, setUserType] = useState('')
    const [userTypeError, setUserTypeError] = useState(false)
    const [userModalImage, setUserModalImage] = useState('')
    const [userIdModal, setUserIdModal] = useState('')
    const [userIdModalConsulta, setUserIdModalConsulta] = useState('')
    const [userModalTitle, setUserModalTitle] = useState('')
    const [userModalInfo, setUserModalInfo] = useState('')
    const [userModalInfoCss, setUserModalInfoCss] = useState({})
    const [userAcceptPolicy, setUserAcceptPolicy] = useState(false)
    const [userAcceptBaja, setUserAcceptBaja] = useState(false)
    const [userConsulta, setUserConsulta] = useState(false)
    const [sendForm, setSendForm] = useState("body_button_disabled")
    const [sendModalForm, setSendModalForm] = useState("body_button_disabled")
    const [sendModalFormBaja, setSendModalFormBaja] = useState("body_button_disabled_Baja")
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showBaja, setShowBaja] = useState(false);
    const handleCloseBaja = () => setShowBaja(false);
    const handleShowBaja = () => setShowBaja(true);
    const [showForm, setShowForm] = useState(true)
    const [showConsulta, setShowConsulta] = useState(false);
    const validatorFormOnSubmit = () => {
        let isValid = true
        let errors = []

        if (!userName) {
            isValid = false
            errors['userName'] = 'Por favor, ingresa tu correo.'
        }

        if (!userId) {
            isValid = false
            errors['userId'] = 'Por favor, ingresa tu identificacion.'
        }

        //setErrors(errors)
        return isValid
    }

    const validateFields = () => {
        let error = false;
        if (userName === "") {
            setUserNameError(true);
            error = true;
        }
        if (userId === "") {
            setUserIdError(true);
            error = true;
        }
        if (userType === "") {
            setUserTypeError(true);
            error = true;
        }
        return error;
    }

    const clickHandlerSubmitSubscribe = (e) => {
        console.log("paso 1");
        e.preventDefault()
        const url = `${process.env.REACT_APP_GATEWAY_URL}${routes.SUBSCRIBE}`;
        if (validateFields()) {return;}
        const params = {
            fullName: userName,
            identification: userId,
            identificationType: userType,
            origin: "CONSENTIMIENTO",
        }
        setUserModalInfoCss({});
        //console.log(params);
        axios
            .post(url, params, {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then((response) => {
                if (response == null || response.data.code !== 200) {
                    let info = response.data.error;
                    let isObject = false;
                    if (messagesSubscribe.has(info)) {
                        info = messagesSubscribe.get(info);
                        if (typeof info === "object") {
                            isObject = true;
                        }
                    }
                    if (isObject) {
                        setUserModalImage(<img className="contentBody_sub1" src={info.image} alt=""/>);
                        setUserModalTitle(info.title);
                        setUserModalInfo(info.message);
                        setUserModalInfoCss(info.css);
                    } else {
                        setUserModalImage(<img className="contentBody_sub1" src={PurdyError} alt=""/>);
                        setUserModalTitle(titlesSubscribe.ERROR);
                        setUserModalInfo(info);
                    }
                    //setUserModalInfo(info);
                    setShow(true);
                } else {
                    let info = response.data.data;
                    let isObject = false;
                    console.log(info);
                    if (messagesSubscribe.has(info)) {
                        info = messagesSubscribe.get(info);
                        if (typeof info === "object") {
                            isObject = true;
                        }
                    }
                    if (isObject) {
                        setUserModalImage(<img className="contentBody_sub1" src={info.image} alt=""/>);
                        setUserModalTitle(info.title);
                        setUserModalInfo(info.message);
                        setUserModalInfoCss(info.css);
                    } else {
                        setUserModalImage(<img className="contentBody_sub1" src={PurdyCorrect} alt=""/>);
                        setUserModalTitle(titlesSubscribe.SUCCESS);
                        setUserModalInfo(info);
                    }
                    setShow(true);
                }
            }).catch(error => {
                setUserModalImage(<img className="contentBody_sub1" src={PurdyError} alt="" />);
                setUserModalTitle(titlesSubscribe.ERROR);
                setUserModalInfo(messagesSubscribe.get("CATCH"));
                setShow(true);
                console.log(error)

            })
    }

    const clickHandlerQueryUser = (e) => {
        //console.log("paso 1");
        e.preventDefault()
        setUserIdModalConsulta("");
        setShowConsulta(false);
        const url = `${process.env.REACT_APP_GATEWAY_URL}${routes.CONSULT}`;
        const params = JSON.stringify({
            identification: userIdModalConsulta
        })
        //console.log(params);
        axios
            .post(url, params, {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then((response) => {
                if (response == null || response.data.code !== 200) {
                    let info = response.data.error;
                    console.log(info);
                    if (messagesConsult.has(info)) {
                        info = messagesConsult.get(info);
                    }
                    setUserModalImage(<img className="contentBody_sub1" src={PurdySinResultados} alt="" />);
                    setUserModalTitle(titlesConsult.NOT_FOUND);
                    setUserModalInfo(info);
                    // setUserModalInfo(<div><p className="idEnModal"><strong>{userIdModalConsulta}</strong></p>
                    //     <p>Que significa esto:</p>
                    //     <p>1. No ha otorgado ningun permiso a Purdy Motor</p>
                    //     <p>2. Se ingreso de forma errada, verifique y vuelva a interntar<strong className="consentimientoTextMargin" onClick={handleOnShowCoincidencia}>acá</strong></p></div>);
                    setShow(true);
                } else {
                    let info = response.data.data;
                    console.log(info);
                    if (messagesConsult.has(info)) {
                        info = messagesConsult.get(info);
                    }
                    setUserModalImage(<img className="contentBody_sub1" src={PurdyPermisos} alt="" />);
                    setUserModalTitle("PERMISOS");
                    setUserModalInfo(<div><p>Resumen:</p>
                        <p>{info}</p>
                        <p className="consentimientoText" onClick={handleOnShowConsentimiento}><strong>Ver consentimiento</strong></p></div>);
                    setShow(true);
                }
            }).catch(error => {
                setUserModalImage(<img className="contentBody_sub1" src={PurdySinResultados} alt="" />);
                setUserModalTitle(titlesConsult.ERROR);
                setUserModalInfo(messagesConsult.get("CATCH"));
                setShow(true);
                console.log(error)

            })
    }

    const clickHandlerUnsubscribe = (e) => {
        console.log("paso 2");
        e.preventDefault();
        setShowBaja(false);
        const url = `${process.env.REACT_APP_GATEWAY_URL}${routes.UNSUBSCRIBE}`;
        const params = JSON.stringify({
            identification: userIdModal
        })
        console.log(params);
        axios
            .post(url, params, {
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then((response) => {
                if (response == null || response.data.code !== 200) {
                    let info = response.data.error;
                    console.log(info);
                    if (messagesUnSubscribe.has(info)) {
                        info = messagesUnSubscribe.get(info);
                    }
                    setUserModalImage(<img className="contentBody_sub1" src={PurdyError} alt="" />);
                    setUserModalTitle(titlesUnSubscribe.ERROR);
                    setUserModalInfo(info);
                    setShow(true);
                } else {
                    let info = response.data.data;
                    console.log(info);
                    if (messagesUnSubscribe.has(info)) {
                        info = messagesUnSubscribe.get(info);
                    }
                    setUserModalImage(<img className="contentBody_sub1" src={PurdyCorrect} alt="" />);
                    setUserModalTitle(titlesUnSubscribe.SUCCESS);
                    setUserModalInfo(info);
                    setShow(true);
                }
            }).catch(error => {
                setUserModalImage(<img className="contentBody_sub1" src={PurdyError} alt="" />);
                setUserModalTitle(titlesUnSubscribe.ERROR);
                setUserModalInfo(messagesUnSubscribe.get("CATCH"));
                setShow(true);
                console.log(error)

            })
    }


    const handleOnChange = (e) => {
        //console.log(e.target);
        if (e.target.name === 'inputUsername') {
            setUserNameError(e.target.value === "");
            setUserName(e.target.value);
        } else if (e.target.name === 'inputUserId') {
            setUserIdError(e.target.value === "");
            setUserId(e.target.value);
        } else if (e.target.name === 'inputUserType') {
            setUserTypeError(e.target.value === "");
            setUserType(e.target.value);
        }

    }

    const handleOnCheckChange = (e) => {
        //console.log(e.target);
        if (userAcceptPolicy === false) {
            setUserAcceptPolicy(true);
            setSendForm("body_button");
        } else if (userAcceptPolicy === true) {
            setUserAcceptPolicy(false);
            setSendForm("body_button_disabled");
        }
    }

    const handleOnChangeModal = (e) => {
        setUserIdModal(e.target.value);
        if (userIdModalConsulta) {
            setUserConsulta(true);
        }
    }

    const handleOnChangeModalConsulta = (e) => {
        setUserIdModalConsulta(e.target.value);
    }

    const handleOnChangeModalCheck = (e) => {
        if (userAcceptBaja === false) {
            setUserAcceptBaja(true);
            setSendModalFormBaja("body_button_baja");
        } else if (userAcceptBaja === true) {
            setUserAcceptBaja(false);
            setSendModalFormBaja("body_button_disabled_Baja");
        }
    }

    const handleOnCloseBajaModal = () => {
        setShow(false);
        if (userAcceptBaja === true) {
            setShowBaja(false);
            setUserIdModal("");
            setUserAcceptBaja(false);
            setSendModalFormBaja("body_button_disabled_Baja");
        }
    }

    const handleShowConsulta = () => {
        setShowConsulta(true);
        setUserModalImage(<img className="contentBody_sub1" src={PurdyRevision} alt="" />);
    }

    const handleOnShowConsentimiento = async () => {
        setShow(false);
        setShowForm(false);

    }

    const handleOnCloseRevision = async () => {
        setShowConsulta(false);
    }

    const handleOnShowCoincidencia = async () => {
        setShow(false);
        setShowForm(true);
    }

    return (
        <div className="body">
            <div className="body_container">
                <h2 className="body_title">CONSENTIMIENTO INFORMADO</h2>
                <div className="body_text">
                    <p className="body_text_sub">
                        Al aceptar el presente documento, hago constar que me han informado que Purdy Motor S.A., con cédula de persona jurídica número 3-101-005744  (en adelante “Grupo Purdy”) custodia y administra una base de datos de carácter personal (la “Base de Datos”) en la cual se almacena, la información brindada en este momento, cualquier otra brindada durante la relación comercial que tengo o eventualmente podrían tener con dicha empresa así como la información que Grupo Purdy pueda solicitar sobre mi en otras fuentes incluyendo pero sin limitarse a protectoras o burós de crédito (la “Información”). La Información incluye, pero no se limita a su nombre, apellidos, número telefónico, lugar de residencia y número de documento vigente de identificación, profesión, salario o detalle de ingresos económicos. Entiendo que la Información podrá ser usada por Grupo Purdy para fines comerciales dentro de los que se incluyen realizar perfiles del cliente o demográficos, manejar un histórico de mis patrones de consumo, enviarme información comercial, publicidad, análisis de crédito, así como servir como información de contacto con fines informativos, darle seguimiento a mis asuntos con Grupo Purdy, brindar servicio posventa y/o análisis de crédito en caso de que solicite un préstamo con Grupo Purdy entre otros. Esta información podría ser sobre las marcas que adquiero o solicito directamente información de Grupo Purdy, así como de marcas de productos o servicios que no hubiese adquirido.
                    </p>
                    <p className="body_text_sub">Entiendo que distintos funcionarios, empleados, representantes y asesores (externos e internos) de Grupo Purdy tendrán acceso a la Información para los mismos fines que Grupo Purdy. Además, esta información podrá ser utilizada por aquellas empresas que formen parte del mismo grupo de interés económico de Grupo Purdy. Asimismo, la presente información se brinda de forma facultativa. Sin embargo, de negarme a brindarlo, Grupo Purdy podría negarse a brindarme algunos servicios o no podría facilitarme alguna información.</p>
                    <p className="body_text_sub">
                        En algunos vehículos, como parte de un contrato adicional con Grupo Purdy o alguna de su subsidarias, se encuentra instalado un dispositivo para brindar servicios de localización vehícular. La información que generen estos vehículos será utilizada principalmente para brindar servicios complementarios post venta (información de citas de mantenimiento, prevención de mantenimiento, etc.) a los clientes y/o servicios de administración de flotas.</p>
                    <p className="body_text_sub">Que tengo el derecho de solicitar el acceso, la rectificación, actualización y eliminación de la Información en los términos que indica la “Ley de Protección de la Persona Frente al Tratamiento de sus Datos Personales” y su reglamento. Asimismo, declaro que se me ha informado que Grupo Purdy y sus afiliadas, son las responsables de la Base de Datos y que conozco la dirección de sus oficinas indicado anteriormente.</p>
                    <p className="body_text_sub">Todos los derechos indicados me indicaron que los puedo ejercer manifestando mi deseo enviando un correo a la dirección <a className={"mail-link"} href="mailto:datos@grupopurdy.com">datos@grupopurdy.com</a>.</p>
                    <p className="body_text_sub"> He leído cuidadosamente el contenido de esta autorización y la comprendo en su totalidad y por medio de la firma del presente documento, consiento de forma libre y expresa a Grupo Purdy, sus empleados, representantes, asesores externos e internos a tratar, recopilar, almacenar para uso de Grupo Purdy, la información relativa a mis datos personales, según lo indicado en el presente documento y/o permitido por la Ley. </p></div>
                <Modal show={show} onHide={handleOnCloseBajaModal}
                    dialogClassName="modal-90w">
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="contentBody_1">
                            {userModalImage}
                        </div>
                        <div className="contentBody_2">
                            {
                                userModalTitle !== "" &&
                                <p className="La-solicitud-de-dars">{userModalTitle}</p>
                            }
                            <span className="La-ccion-ha-sido-co" style={userModalInfoCss}>{userModalInfo}</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button variant="secondary" onClick={handleOnCloseBajaModal}>
                            Close
                        </Button> */}
                    </Modal.Footer>
                </Modal>
                <Modal show={showBaja} onHide={handleCloseBaja}>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="contenedorBaja">
                            <div className="contenedorBaja_sub">
                                <p className="La-solicitud-de-dars_1">DARSE DE BAJA</p>
                            </div>
                            <div>
                                <input
                                    className="customInput inputCustom"
                                    type='text'
                                    onChange={handleOnChangeModal}
                                    name="inputUserModalId"
                                    placeholder="Número de identificación*"
                                    value={userIdModal}
                                />
                            </div>
                            <label className="container_Checks_Baja">
                                <div>
                                    <input
                                        className="regular-checkbox"
                                        name="isAuthorize"
                                        type="checkbox"
                                        checked={userAcceptBaja}
                                        onChange={handleOnChangeModalCheck} />
                                </div>
                                <span> Deseo darme de baja de la base de datos de Purdy Motor</span>
                            </label>
                            <input type="button"
                                className={sendModalFormBaja}
                                value="Enviar"
                                onClick={clickHandlerUnsubscribe} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
                <Modal show={showConsulta} onHide={handleOnCloseRevision}>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="contentBody_1">
                            {userModalImage}
                        </div>
                        <div className="contentBody_2"><p className="La-solicitud-de-dars">REVISIÓN DE PERMISOS</p>
                            <span className="La-ccion-ha-sido-co">
                                <input
                                    className="customInput inputCustomWidth"
                                    type='text'
                                    onChange={handleOnChangeModalConsulta}
                                    name="inputUserModalConsultaId"
                                    placeholder="Número de identificación*"
                                    value={userIdModalConsulta}
                                />
                                <input type="button"
                                    className="body_button_baja noMargin"
                                    value="Enviar"
                                    onClick={clickHandlerQueryUser} /></span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
                <div>
                    {showForm && (
                        <form>
                            <Row className="row justify-content-md-center">
                                <Col className="inputs_container col-3">
                                    <div className="customMargin input-container">
                                        <input
                                            id="inputUsername"
                                            className="customInput customMarginTop"
                                            type='text'
                                            onChange={handleOnChange}
                                            name="inputUsername"
                                            placeholder="Nombre completo*"
                                            value={userName}
                                            required="required"
                                        // validation={errors['userName']}
                                        />
                                        {userNameError &&
                                            <span className="input-error">{formVariables.REQUIRED}</span>
                                        }
                                    </div>
                                    <div className="customMargin input-container">
                                        <select
                                            value={userType}
                                            onChange={handleOnChange}
                                            name="inputUserType"
                                            className="customSelect"
                                            required="required"
                                        >
                                            <option hidden value="">Tipo de identificación</option>
                                            <option value="person">Cédula de identidad</option>
                                            <option value="legal">Cédula jurídica</option>
                                            <option value="dimex">DIMEX</option>
                                            <option value="passport">Pasaporte</option>
                                            <option value="other">Otro tipo de identificación</option>
                                        </select>
                                        {userTypeError &&
                                            <span className="input-error">{formVariables.REQUIRED}</span>
                                        }
                                    </div>
                                    <div className="input-container">
                                        <input
                                            className="customInput"
                                            type='text'
                                            onChange={handleOnChange}
                                            name="inputUserId"
                                            placeholder="Número de identificación*"
                                            value={userId}
                                            required="required"
                                        // validation={errors['userId']}
                                        />
                                        {userIdError &&
                                            <span className="input-error">{formVariables.REQUIRED}</span>
                                        }
                                    </div>
                                    <div>
                                        <label className="input_Label_Check">
                                            <div>
                                                <input
                                                    className="regular-checkbox"
                                                    name="isAcceptingPolicy"
                                                    type="checkbox"
                                                    checked={userAcceptPolicy}
                                                    onChange={handleOnCheckChange} />
                                            </div>
                                            <span>Al seleccionar esta casilla, reconozco he leído cuidadosamente el contenido de esta autorización y la comprendo en su totalidad.</span>
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <Col className="button_container">
                                <Row justify='space-around' align='middle'>
                                    <Col>
                                        <input type="button"
                                            className={sendForm}
                                            value="Enviar"
                                            onClick={clickHandlerSubmitSubscribe} />
                                    </Col>
                                </Row>
                            </Col>
                        </form>)}
                    {/*<div className="container_sup">*/}
                    {/*    <div className="button_container bg-blue">*/}
                    {/*        <div className="container_sub">*/}
                    {/*            <div className="container_sub_1">*/}
                    {/*                <p className="container_span_left">Deseo que me remueva de las bases de datos de Purdy Motor.</p>*/}
                    {/*                <input type="button"*/}
                    {/*                    className="body_button_sub buttonMLeft"*/}
                    {/*                    value="DARME DE BAJA"*/}
                    {/*                    onClick={handleShowBaja} />*/}
                    {/*            </div>*/}
                    {/*            <div className="container_sub_middle"></div>*/}
                    {/*            <div className="container_sub_2">*/}
                    {/*                <p className="container_span_right">Deseo revisar los permisos que le he concedido a Purdy Motor.</p>*/}
                    {/*                <input type="button"*/}
                    {/*                    className="body_button_sub buttonMRight"*/}
                    {/*                    value="REVISAR"*/}
                    {/*                    onClick={handleShowConsulta} />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <footer>
                        <div className="footer-social">
                            <div className="footer-logo-mobile">
                                <a className="logo" href={process.env.REACT_APP_MAIN_PAGE_URL} target="_blank" rel="noreferrer">Grupo Purdy Motor</a>
                            </div>
                            <h3 className="Social">SOCIAL</h3>
                            <div className="footer-social-links">
                                <a className="icon_mRight facebook" href={variables.FACEBOOK} target="_blank" rel="noreferrer"/>
                                <a className="icon_mRight linkedin" href={variables.LINKEDIN} target="_blank" rel="noreferrer"/>
                                <a className="icon_mRight youtube" href={variables.YOUTUBE} target="_blank" rel="noreferrer"/>
                            </div>
                        </div>
                        <div className="footer-info">
                            <div className="footer-logo">
                                <a className="logo" href={process.env.REACT_APP_MAIN_PAGE_URL} target="_blank" rel="noreferrer">Grupo Purdy Motor</a>
                            </div>
                            <div className="footer-links">
                                {/*<a target="_blank" href="#" className="links_mRight" rel="noreferrer">REGLAMENTO</a><span>|</span>*/}
                                <a target="_blank" href={variables.TERMS} className="links_mRight links_mLeft" rel="noreferrer">TÉRMINOS Y CONDICIONES</a><span>|</span>
                                <a target="_blank" href={variables.COOKIES} className="links_mRight links_mLeft" rel="noreferrer">COOKIES</a>
                                {/*<a target="_blank" href="#" className="links_mLeft" rel="noreferrer">USO DE DATOS</a>*/}
                            </div>
                            <p className="copy">© Purdy Motor {(new Date()).getFullYear()} Derechos Reservados</p>
                        </div>
                        <div className="footer-last">
                            <div className="footer-last-links">
                                <span className="icn icn-4 icn-whatsapp-none"></span>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div >
    )
}


export default Body
