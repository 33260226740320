import React from 'react'
import './styles/Header.css';
import PurdyLogo from '../assets/logo_gpm.png'
import PurdyEspañol from '../assets/spain.svg'
import { useMediaQuery } from 'react-responsive'
import variables from "../constants/variables";

function Header() {

    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' })
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    return (
        <div className="header">
            <div className="header__logo">
                <a className="logo" href={process.env.REACT_APP_MAIN_PAGE_URL} target="_blank" rel="noreferrer">Grupo Purdy Motor</a>
                {/* <img src={PurdyLogo} alt="" /> */}
            </div>
            {isTabletOrMobile &&
                <div className="header__center">
                </div>
            }

            {isBigScreen &&
                <div className="header__right">
                    <img src={PurdyEspañol} alt="" />
                </div>
            }

            {isTabletOrMobile &&
                <div className="header__right">
                </div>
            }

        </div>
    )
}


export default Header
