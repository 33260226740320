const variables = {
    WHATSAPP: "https://api.whatsapp.com/send?phone=+50685891000&amp;text=Hola%20quiero%20informaci%C3%B3n%20de%20Grupo%20Purdy",
    FACEBOOK: "https://www.facebook.com/grupopurdy",
    LINKEDIN: "https://www.linkedin.com/company/grupo-purdy",
    YOUTUBE: "https://www.youtube.com/channel/UCcbyC21-iiAJ7gVUzzEkR9Q",
    PRESS: "http://www.grupopurdy.com//notas-de-prensa",
    TERMS: "https://www.grupopurdy.com/wp-content/uploads/2021/01/Términos-y-Condiciones-Sitio-Grupo-Purdy.pdf",
    COOKIES: "https://www.grupopurdy.com/wp-content/uploads/2019/08/politica-de-cookies.pdf",
}

export default variables;