import PurdyAlert from "../assets/icono-alerta.svg"

export const messagesSubscribe = new Map([
    // Subscribe endpoint
    ["Consentimiento informado existente", {
        message: "Parece que el consentimiento relacionado a este número de identificación ya fue otorgado. Si creés que fue un error, por favor intentá de nuevo.",
        title: "",
        image: PurdyAlert,
        css: { fontWeight: 'bolder', fontSize: '20px'}

    }],
    ["El formato de la identificación no es válido", "El formato de la identificación no es válido."],
    ["Consentimiento informado asignado correctamente", "Gracias, tu consentimiento fue otorgado correctamente."],
    ["CATCH", "No hemos podido concluir con éxito el proceso, por favor vuelva a intentarlo y asegurese que la información es correcta."],
]);

export const messagesUnSubscribe = new Map([
    // Unsubscribe
    ["Consentimiento informado no existente", "Parece que no hay un consentimiento relacionado a este número de identificación y por tanto, no te podemos dar de baja. Si creés que fue un error, por favor intentá de nuevo."],
    ["Consentimiento informado dado de baja exitósamente", "Te confirmamos que tu consentimiento fue dado de baja."],
    ["CATCH", "No hemos podido concluir con éxito el proceso, por favor vuelva a intentarlo y asegurese que la información es correcta."],
]);

export const messagesConsult = new Map([
    // Consult
    ["Consentimiento informado existente", "En el siguiente enlace podrás revisar en detalle los permisos otorgados a Grupo Purdy."],
    ["Consentimiento informado no existente", "Parece que no hay un consentimiento relacionado a este número de identificación y por tanto, no te podemos darte detalle del mismo. Si creés que fue un error, por favor intentá de nuevo."],
    ["CATCH", "No fue posible completar la consulta."],
]);

export const titlesSubscribe = {
    SUCCESS: "GRACIAS POR SER PARTE DE LA FAMILIA GRUPO PURDY",
    ERROR: "HA SURGIDO UN INCONVENIENTE CON LA ACCIÓN"
};

export const titlesUnSubscribe = {
    SUCCESS: "GRACIAS POR SER PARTE DE LA FAMILIA GRUPO PURDY",
    ERROR: "HA SURGIDO UN INCONVENIENTE CON LA ACCIÓN"
};

export const titlesConsult = {
    SUCCESS: "NO HEMOS ENCONTRADO NINGUNA COINCIDENCIA",
    NOT_FOUND: "NO HEMOS ENCONTRADO NINGUNA COINCIDENCIA",
    ERROR: "OCURRIO UN ERROR DURANTE LA CONSULTA"
}

export const formVariables = {
    REQUIRED: "*Este campo es requerido"
}
